import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
  selectedDate = false;
  status = ''
  constructor( public dialogRef: MatDialogRef<ConfirmDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    this.status= data;
   }
  ok(){
    this.selectedDate =true;
    this.dialogRef.close(this.selectedDate);
  }
  no(){
    this.dialogRef.close(this.selectedDate);
  }
}
