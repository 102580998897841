import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
} 

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
 {
    state: 'barber',
    name: 'Shops',
    type: 'link',
    icon: 'store'
  },
  {
    state: 'mybooking',
    name: 'My Appointment',
    type: 'link',
    icon: 'event_seat'
  },
  {
    state: 'Userprofile',
    name: 'My Profile',
    type: 'link',
    icon: 'face'
  }
];
const barberMenu=[
  {
    state: `service`,
    name: 'Services',
    type: 'link',
    icon: 'equalizer'
  },
  {
    state: 'mybooking',
    name: 'My Appointment',
    type: 'link',
    icon: 'event_seat'
  },
  {
    state: 'shop',
    name: 'Shop',
    type: 'link',
    icon: 'store'
  }
]
const adminMenu=[
  {
    state: 'barber',
    name: 'Shops',
    type: 'link',
    icon: 'store'
  },
  {
    state: 'Userprofile',
    name: 'My Profile',
    type: 'link',
    icon: 'face'
  }
  ,
  {
    state: 'customer',
    name: 'Customers',
    type: 'link',
    icon: 'people'
  }
]

@Injectable()
export class MenuItems {
 

  getMenuitem(): Menu[] {
    let user= localStorage.getItem('userData');
    let userData = user && JSON.parse(user);
    if(userData.userRole == 'customer' ){
      return MENUITEMS;
    }
    if(userData.userRole == 'barber')
    {
      return barberMenu;
    }
    return adminMenu;
  }

}
