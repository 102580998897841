import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PasswordValidator } from '../../shared/password.validators';

@Component({
  selector: 'app-verify-pass',
  templateUrl: './verify-pass.component.html',
  styleUrls: ['./verify-pass.component.css']
})
export class VerifyPassComponent implements OnInit {

  loginForm: FormGroup;
  loginFormErrors: any;
  constructor(private router: Router, private authService: AuthService, private formBuilder: FormBuilder, private toast : ToastrService) { 
     this.loginFormErrors = {
      NewPassword: [''],
      NewConfirmPassword: ['']   
    };
  }
  currentUrl:any;
  showBox:boolean=false;
  ngOnInit() {
    this.currentUrl = window.location.href;
    let query = this.currentUrl? this.currentUrl.split('?'):this.currentUrl;
    if(query && query.length>1){
      this.authService.getData('same/verify?'+query[1]).subscribe(response => {
        this.authService.setLoader(false);
          const res = response;
          if(res.message)
            {
              if(res.process!=='compelte'){
                this.showBox=true;
              }
              this.toast.success(res.message);
              console.log("here")
            }
      }, error => {
          this.authService.setLoader(false);
          this.toast.error(error);
        });
    }
    this.loginForm = this.formBuilder.group({
      NewPassword: ['', [Validators.required]],
      NewConfirmPassword: ['', [Validators.required]]
    }, { validator: PasswordValidator });

  }

  onLoginFormValuesChanged() {
    for (const field in this.loginFormErrors) {
      if (!this.loginFormErrors.hasOwnProperty(field)) {
        continue;
      }

      this.loginFormErrors[field] = {};

      const control = this.loginForm.get(field);

      if (control && control.dirty && !control.valid) {
        this.loginFormErrors[field] = control.errors;
      }
    }
  }

  save(data:any) {  
    this.authService.setLoader(true);
    let token = window.location.href;
    token = token.split('?')[1];
    let value= {
        pass1:data.NewPassword,
        pass2:data.NewConfirmPassword,
        token
    };
    this.authService.postData('same/set-password',value).subscribe(response => {
        this.authService.setLoader(false);
      if (response.status == "success") {
        this.toast.success('Password set Successfully...');
        this.router.navigate(['/Userprofile']);
      }
    }, error => {
        this.authService.setLoader(false);
        this.toast.error(error);
      });
  }

}
