import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material'
import { AuthService } from '../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
 options = [
    { value: 'User', imageUrl: '../../assets/imgs/Logo.png' },
    { value: 'Barber', imageUrl: '../../assets/imgs/Logo.png' },
  ];

 selectedOption: string='User'; 
 registerForm: FormGroup;
  registerFormErrors: any;
  constructor(private router: Router, private authService: AuthService, private formBuilder: FormBuilder, private toast : ToastrService,  private route:ActivatedRoute,) {
    this.registerFormErrors = {
      email: {},
    };
  } 

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });

    this.registerForm.valueChanges.subscribe(() => {
      this.onregisterFormValuesChanged(); 
    });
  }

   onSubmit(form) {
    this.authService.setLoader(true);
    let value= {
      email: form.value.email,
      character:this.selectedOption
    }
    this.authService.postData('same/register',value).subscribe(response => {
      this.authService.setLoader(false);
      const res = response;
      if(res.message)
        {
          // setTimeout(()=>{
          //    window.location.reload();  
          // },2000) 
          this.toast.success(res.message); 
          this.ngOnInit()
        }
    }, error => {
        this.authService.setLoader(false);
        this.toast.error(error);
      });
}

onregisterFormValuesChanged() {
  for (const field in this.registerFormErrors) {
    if (!this.registerFormErrors.hasOwnProperty(field)) {
      continue;
    }

    // Clear previous errors
    this.registerFormErrors[field] = {};

    // Get the control
    const control = this.registerForm.get(field);

    if (control && control.dirty && !control.valid) {
      this.registerFormErrors[field] = control.errors;
    }
  }
}
}

