import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AuthGuard } from './guards/auth.guard';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoginComponent } from './login/login.component';
import { RoleGuard } from './guards/role.guard';
import { DriverGuardGuard } from './guards/driver-guard.guard';
import { LoginGuard } from './guards/login.guard';
import { RegisterComponent } from './register/register.component';
import { VerifyPassComponent } from './verify-pass/verify-pass.component';
import { BookingComponent } from './booking/booking.component';
import { ServiceComponent } from './service/service.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { AboutusComponent } from './aboutus/aboutus.component';
export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: '/welcome',
    pathMatch: 'full'
  },
   {
    path: 'welcome',
   component: WelcomeComponent,
    data: { title: 'Welcome' },
    // loadChildren: './welcome/welcome.module#WelcomeModule',
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate:[LoginGuard],
    data: { title: 'Login' },
  },
    {
    path: 'about',
    component: AboutusComponent,
    data: { title: 'About Us' }
  },
  {
    path:'register',
    component: RegisterComponent,
    data: { title: 'Register' },
  },
  {
    path:'verify',
    component: VerifyPassComponent,
    data: { title: 'Verify' },
  },
  {
    path: 'forget-password',
    loadChildren: './forget-password/forget-password.module#ForgetPasswordModule',
    data: { title: 'Forget-Password' },
  },
  {
    path: 'new-password',
    loadChildren: './new-password/new-password.module#NewPasswordModule',
    data: { title: 'New-Password' }
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
       {
        path:'booking',
        loadChildren: './booking/booking.module#BookingModule',
        data: { title: 'Booking' },
      },
      {
        path:'barber',
        loadChildren: './barber/barber.module#BarberModule',
        data: { title: 'All' },
      },
       {
        path:'mybooking',
        loadChildren:'./mybooking/mybooking.module#MybookingModule',
        data: { title: 'Mybooking' },
      },
      {
        path:'appointment',
        loadChildren:'./appointment/appointment.module#AppointmentModule',
        data: { title: 'Appointment' },
      },
      {
        path: 'reset',
        component: ResetPasswordComponent,
        data: { title: 'Reset-password' }
      },
      {
        path: 'service',
        loadChildren: './service/service.module#ServiceModule',
        data: { title: 'Services' }
      },
      {
        path: 'customer',
        loadChildren: './customer/customer.module#CustomerModule',
        data: { title: 'Customer' }
      },
     {
        path: 'icons',
        loadChildren: './icons/mat-icon.module#IconsModule'
      },
      {
        path:'profile',
        canActivate: [RoleGuard],
        loadChildren: './profile/profile.module#ProfileModule'
      },
      {
        path: 'userReset',
        component: ResetPasswordComponent,
        data: { title: 'Reset-password' }
      },
      {
        path:'shop',
        loadChildren: './shop/shop.module#ShopModule',
         data: { title: 'Shop Detail' }
      },
      {
        path:'Userprofile',
        canActivate: [DriverGuardGuard],
        loadChildren: './profile/profile.module#ProfileModule'
      }
    ]
  }
];
