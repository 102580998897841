import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { BehaviorSubject } from 'rxjs';

// const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  updateData(arg0: string, value: any) {
    throw new Error('Method not implemented.');
  }
  private userData = new Subject<any>();
  private userId = new BehaviorSubject<any>({});
  userData$ = this.userData.asObservable();
  loader = new BehaviorSubject(false);
  localDataValuesChange: Subject<any> = new Subject<any>();
  private second = new Subject<any>();
  second$ = this.second.asObservable();
  pdfUrl:string = 'https://witco.s3.ap-southeast-1.amazonaws.com'
  baseUrl : string = 'api/v1/';
  token;
  constructor(private http: HttpClient, private router: Router) { 
    this.token = localStorage.getItem('token');
  }

  //  setHeaders() {
  //   let headers: HttpHeaders = new HttpHeaders();
  //   headers = headers.set('Accept', 'application/json');
  //   headers = headers.set('Content-Type', 'application/json');
   
  //   return headers;
  // }
  
  setUserId(data) {
    this.userId.next(data);
  }

  getUserId() {
    return this.userId.asObservable();
  }

  sendId(data){
    this.second.next(data);
  }

  sendUserData(data){
    this.userData.next(data);
  }

  public  getData(url, data?: any): Observable<any>{
    // let headers = this.setHeaders();
    if (data){
      const queryParams =  Object.keys(data).map(key => key + '=' + data[key]).join('&');
      url += '?' + queryParams;
    }

    // const token = 'Bearer' + ' ' + this.token;
    // headers = headers.set('Authorization', token);
    // const options = { headers: headers };
    return this.http.get(this.baseUrl + url);
  }

  public postData(url?:any, data?: any): Observable<any> {
    // let headers = this.setHeaders();
    // const options = { headers: headers };
    return this.http.post(this.baseUrl + url, data);
  }

  public deleteData(url): Observable<any>{
    // const headers = this.setHeaders();
    // const options = { headers: headers };
    return this.http.delete(this.baseUrl + url);
  }

  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  private handleError(error: any) {
    const errMsg = error.error;
    if (typeof errMsg.message === 'object') {
      const arr = Array.from(Object.keys(errMsg.message), k => errMsg.message[k]);
      return throwError(arr);
    }
    return throwError(errMsg.message);
  }

  setLoader(value) {
    this.loader.next(value);
  }

  getLoader() {
    return this.loader.asObservable();
  }
  getAllData(url:any){
    // let headers = this.setHeaders();
    // const options = { headers: headers };
    return this.http.get(this.baseUrl+url)
  }
  patchData(url:any,data:any){
    return this.http.patch(this.baseUrl+ url,data);
  }
  delete(url:any){
    return this.http.delete(this.baseUrl+ url);
  }
}