import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material'
import { AuthService } from '../services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  loginFormErrors: any;
  latitude:any;
  longitude:any
  showPassword:boolean = false;
  options:any= [
    { value: 'User', imageUrl: '../../assets/imgs/Logo.png' },
    { value: 'Barber', imageUrl: '../../assets/imgs/Logo.png' },
  ];
  selectedOption:any='User' 
  constructor(private router: Router, private authService: AuthService, private formBuilder: FormBuilder, private toast : ToastrService) {
    this.loginFormErrors = {
      email: {},
      password: {}
    };
  } 

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.loginForm.valueChanges.subscribe(() => {
      this.onLoginFormValuesChanged(); 
    });
  }

   onSubmit(data) {
    this.authService.setLoader(true);
    this.authService.postData('user/check',{"email":data.email}).subscribe(response => {
      this.authService.setLoader(false);
      if(response.data == true){
               let value= {
                  email: data.email,
                  password:data.password,
                  // loc:[this.latitude,this.longitude],
                  character:this.selectedOption
                }
                this.authService.postData('user/login',value).subscribe(response => {
                  this.authService.setLoader(false);
                  if (response.data) {
                    const res = response;
                    localStorage.setItem('token', res.token);
                    localStorage.setItem('userData',JSON.stringify(res.data.user));
                    let user= localStorage.getItem('userData');
                    let userData = user && JSON.parse(user);
                    
                    if(userData.userRole == 'customer'){
                    this.router.navigateByUrl('/profile');
                    }
                    if(userData.userRole == 'admin')
                    {
                      this.router.navigateByUrl('/profile');
                    }
                     if(userData.userRole == 'barber')
                    {
                      this.router.navigateByUrl('/service');
                    }
                  }
                }, error => {
                    this.authService.setLoader(false);
                    this.toast.error(error);
                  });      
      }else{
        let value= {
          email: data.email,
          password:data.password,
        }
        this.authService.postData('user/login',value).subscribe(response => {
          this.authService.setLoader(false);
          if (response.data) {
            const res = response;
            localStorage.setItem('token', res.token);
            localStorage.setItem('userData',JSON.stringify(res.data.user));
            let user= localStorage.getItem('userData');
            let userData = user && JSON.parse(user);
            
            if(userData.userRole == 'customer'){
            this.router.navigateByUrl('/Userprofile');
            }
            if(userData.userRole == 'admin')
            {
              this.router.navigateByUrl('/Userprofile');
            }
            if(userData.userRole == 'barber')
            {
              this.router.navigateByUrl('/shop');
            }
          }
        }, error => {
            this.authService.setLoader(false);
            this.toast.error(error);
          });

      }
    }, error => {
      this.authService.setLoader(false);
      this.toast.error(error);
    });
}

onLoginFormValuesChanged() {
  for (const field in this.loginFormErrors) {
    if (!this.loginFormErrors.hasOwnProperty(field)) {
      continue;
    }

    // Clear previous errors
    this.loginFormErrors[field] = {};

    // Get the control
    const control = this.loginForm.get(field);

    if (control && control.dirty && !control.valid) {
      this.loginFormErrors[field] = control.errors;
    }
  }
}
togglePasswordVisibility() {
  this.showPassword = !this.showPassword;
}
}
